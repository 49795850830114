var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('sidebar-toggle-button'),_c('template',{slot:"links"},[(this.$global.menuDashboard)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.dashboard'),
          icon: 'fas fa-chart-line',
          path: '/dashboard'
        }}}):_vm._e(),(this.$global.menuAdsDesign)?_c('sidebar-item',{attrs:{"link":{
          name: 'Ads Design',
          icon: 'fas fa-drafting-compass'
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Banner' }}},[_c('sidebar-item',{attrs:{"link":{
                  name: 'create',
                  path: '/banner/create',
                }}}),_c('sidebar-item',{attrs:{"link":{
                  name: 'list',
                  path: '/banner/list',
                }}})],1),_c('sidebar-item',{attrs:{"link":{
                  name: 'Clients',
                  path: '/client'
                }}})],1):_vm._e(),(this.$global.menuCampaign)?_c('sidebar-item',{attrs:{"link":{
          name: 'Campaign', 
          icon: 'fas fa-bullhorn',
        }}},[_c('sidebar-item',{attrs:{"link":{
                  name: 'Dashboard',
                  path: '/campaign',
                }}}),_c('sidebar-item',{attrs:{"link":{
                  name: 'Create Campaign',
                  path: '/campaign-setup'
                }}}),_c('sidebar-item',{attrs:{"link":{
                  name: 'Campaign Audience List',
                  path: '/audience'
                }}}),_c('sidebar-item',{attrs:{"link":{
                  name: 'Clients',
                  path: '/client'
                }}})],1):_vm._e(),((this.$global.menuLeadsPeek && this.$global.menuUserType != 'client' && !this.$global.systemUser && this.$global.creditcardsetup && this.$global.stripeaccountconnected && this.activesidebar['local']) || (this.$global.menuLeadsPeek && this.$global.menuLeadsPeek_type == 'local' && this.$global.menuUserType == 'client' && !this.$global.systemUser && this.activesidebar['local']))?_c('sidebar-item',{attrs:{"link":{
          name: this.$global.globalModulNameLink.local.name,
          icon: 'far fa-eye'
        }}},[_c('sidebar-item',{attrs:{"link":{
                  name: 'Dashboard',
                  path: '/' + this.$global.globalModulNameLink.local.url + '/dashboard'
                }}}),((this.$global.menuLeadsPeek && this.$global.menuUserType != 'client' && !this.$global.systemUser && this.$global.creditcardsetup && this.$global.stripeaccountconnected) || (this.$global.menuLeadsPeek && this.$global.menuLeadsPeek_type == 'local' && this.$global.menuUserType == 'client' && this.$global.disabledaddcampaign))?_c('sidebar-item',{attrs:{"link":{
                  name: 'Campaign Management',
                  path: '/' + this.$global.globalModulNameLink.local.url + '/campaign-management'
                }}}):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{
                  name: 'Leads Management',
                  path: '/' + this.$global.globalModulNameLink.local.url + '/leads-management'
                }}}):_vm._e()],1):_vm._e(),((this.$global.menuLeadsPeek && this.$global.menuUserType != 'client'  && !this.$global.systemUser && this.$global.creditcardsetup && this.$global.stripeaccountconnected && this.activesidebar['locator']) || (this.$global.menuLeadsPeek && this.$global.menuLeadsPeek_typeLocator == 'locator' && this.$global.menuUserType == 'client'  && !this.$global.systemUser && this.activesidebar['locator'] ))?_c('sidebar-item',{attrs:{"link":{
          name: this.$global.globalModulNameLink.locator.name,
          icon: 'fas fa-map-marked'
        }}},[_c('sidebar-item',{attrs:{"link":{
                  name: 'Dashboard',
                  path: '/' + this.$global.globalModulNameLink.locator.url + '/dashboard'
                }}}),((this.$global.menuLeadsPeek && this.$global.menuUserType != 'client'  && !this.$global.systemUser && this.$global.creditcardsetup && this.$global.stripeaccountconnected) || (this.$global.menuLeadsPeek && this.$global.menuLeadsPeek_typeLocator == 'locator' && this.$global.menuUserType == 'client' && this.$global.disabledaddcampaign))?_c('sidebar-item',{attrs:{"link":{
                  name: 'Campaign Management',
                  path: '/' + this.$global.globalModulNameLink.locator.url + '/campaign-management'
                }}}):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{
                  name: 'Leads Management',
                  path: '/' + this.$global.globalModulNameLink.locator.url + '/leads-management'
                }}}):_vm._e()],1):_vm._e(),(((this.$global.menuLeadsPeek && this.$global.menuUserType != 'client'  && !this.$global.systemUser && this.$global.creditcardsetup && this.$global.stripeaccountconnected && this.activesidebar['enhance']) || (this.$global.menuLeadsPeek && this.$global.menuLeadsPeek_typeLocator == 'locator' && this.$global.menuUserType == 'client'  && !this.$global.systemUser && this.activesidebar['enhance'])) && (this.$global.globalModulNameLink.enhance.name != null) && (this.$global.globalModulNameLink.enhance.url != null))?_c('sidebar-item',{attrs:{"link":{
          name: this.$global.globalModulNameLink.enhance.name,
          icon: 'fa-solid fa-angles-up'
        }}},[_c('sidebar-item',{attrs:{"link":{
                  name: 'Dashboard',
                  path: '/' + this.$global.globalModulNameLink.enhance.url + '/dashboard'
                }}}),((this.$global.menuLeadsPeek && this.$global.menuUserType != 'client'  && !this.$global.systemUser && this.$global.creditcardsetup && this.$global.stripeaccountconnected) || (this.$global.menuLeadsPeek && this.$global.menuLeadsPeek_typeLocator == 'locator' && this.$global.menuUserType == 'client' && this.$global.disabledaddcampaign))?_c('sidebar-item',{attrs:{"link":{
                  name: 'Campaign Management',
                  path: '/' + this.$global.globalModulNameLink.enhance.url + '/campaign-management'
                }}}):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{
                  name: 'Leads Management',
                  path: '/' + this.$global.globalModulNameLink.enhance.url + '/leads-management'
                }}}):_vm._e()],1):_vm._e(),(false)?_c('hr'):_vm._e(),((this.$global.menuLeadsPeek && this.$global.menuUserType == 'client' && false))?_c('sidebar-item',{attrs:{"link":{
          name: 'Add New Campaign',
          icon: 'far fa-plus-circle',
          path: '/user/questionnaire-add'
        }}}):_vm._e(),(((this.$global.menuLeadsPeek && this.$global.menuUserType != 'client' && this.$global.menuUserType != 'sales') || (this.$global.menuUserType == 'sales' && this.$global.stripeaccountconnected && this.$global.isAccExecutive == 'T')))?_c('sidebar-item',{attrs:{"link":{
          name: 'SYSTEM SETTINGS',
          icon: 'fas fa-tools'
        }}},[(!this.$global.systemUser && this.$global.creditcardsetup && this.$global.stripeaccountconnected)?_c('sidebar-item',{attrs:{"link":{
                  name: 'Client Management',
                  path: '/configuration/client-management'
                }}}):_vm._e(),(this.$global.systemUser)?_c('sidebar-item',{attrs:{"link":{
                  name: 'Agency List',
                  path: '/configuration/agency-list'
                }}}):_vm._e(),(this.$global.menuUserType != 'sales')?_c('sidebar-item',{attrs:{"link":{
                  name: 'Administrator List',
                  path: '/configuration/administrator-list'
                }}}):_vm._e(),(this.$global.systemUser && this.$global.menuUserType != 'sales')?_c('sidebar-item',{attrs:{"link":{
                name: 'Sales Account List',
                path: '/configuration/sales-account-list'
              }}}):_vm._e(),(this.$global.menuUserType != 'sales' && false)?_c('sidebar-item',{attrs:{"link":{
                  name: 'Role List',
                  path: '/configuration/role-list'
                }}}):_vm._e(),(this.$global.menuUserType != 'client')?_c('sidebar-item',{attrs:{"link":{
                  name: 'General Settings',
                  path: '/configuration/general-setting'
                }}}):_vm._e(),(this.$global.systemUser && this.$global.menuUserType != 'sales' && false)?_c('sidebar-item',{attrs:{"link":{
                  name: 'Data Enrichment',
                  path: '/configuration/data-enrichment'
                }}}):_vm._e(),(this.$global.systemUser && this.$global.menuUserType != 'sales')?_c('sidebar-item',{attrs:{"link":{
                name: 'Opt-Out List',
                path: '/configuration/opt-out-list'
              }}}):_vm._e(),(this.$global.systemUser && this.$global.menuUserType != 'sales' && this.$global.rootcomp)?_c('sidebar-item',{attrs:{"link":{
                name: 'Report Analytics',
                path: '/configuration/report-analytics'
              }}}):_vm._e()],1):_vm._e(),_c('div',{staticStyle:{"height":"50px"}})],1)],2),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }